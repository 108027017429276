// import Vue from 'vue/dist/vue.js'
import Vue from 'vue/dist/vue.min.js'
import axios from 'axios'

const app = new Vue({
  // eslint-disable-line
  el: '#form',
  data: {
    name: '',
    mail: '',
    body: '',
    status: '入力',
    confirmStatus: '確認',
    confirmStyle: 'opacity: .7',
    inputStyle: 'display: block',
    submitStyle: 'display: none',
    sendStyle: ''
  },
  methods: {
    confirm: function () {
      if (this.status === '入力') {
        if (this.$el.checkValidity()) {
          this.status = '確認'
        } else {
          document.getElementById('submit').click()
        }
      } else {
        this.status = '入力'
      }
    },
    chkValidation: function () {
      if (!this.$el.checkValidity()) {
        document.getElementById('submit').click()
        this.confirmStyle = 'opacity: .7'
      } else {
        this.confirmStyle = 'opacity: 1'
      }
    },
    submit: function () {
      if (this.status === '確認') {
        this.status = '送信'
        axios
          .get('http://hakolab.co.jp/api/sendMail.cgi', {
            params: {
              name: app.name,
              mail: app.mail,
              body: app.body
            }
          })
          .then(function (res) {
            if (res.data === 'success\n') {
              app.status = '送信成功'
            } else if (res.data === 'error_customer\n') {
              app.status = 'error_customer'
            } else if (res.data === 'error_self\n') {
              app.status = 'error_self'
            }
          })
          .catch(function (e) {
            app.status = 'error_request'
          })
      }
    },
    fixMailWidth: function () {
      this.mail = this.mail.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      })
      this.mail = this.mail.replace(/[＠]/g, function (s) {
        return '@'
      })
      this.mail = this.mail.replace(/[。]/g, function (s) {
        return '.'
      })
      this.mail = this.mail.replace(/[・ ／]/g, function (s) {
        return '/'
      })
    }
  },
  watch: {
    status: function () {
      if (this.status === '入力') {
        this.chkValidation()
        this.confirmStatus = '確認'
        this.inputStyle = 'display: block'
        this.submitStyle = 'display: none'
        this.sendStyle = 'opacity: 1'
      } else if (this.status === '確認') {
        this.confirmStatus = '修正'
        this.confirmStyle = 'opacity: .7'
        this.inputStyle = 'display: none'
        this.submitStyle = 'display: block'
      } else if (this.status === '送信') {
        this.sendStyle = 'transition: 1s; opacity: 0'
      } else if (this.status === '送信成功') {
        setTimeout(() => {
          const message = document.createElement('p')
          message.textContent =
            '送信が完了しました。確認メールを送信いたしましたので、返信をお待ち下さい。'
          message.className = 'sendSuccess'
          this.$el.style = 'display: flex'
          this.sendStyle = 'display: none'
          this.$el.appendChild(message)
        }, 1000)
      } else if (this.status === 'error_customer') {
        alert(
          '送信に失敗しました。\n・メールアドレスが正しいか\nを確認して再度お試しください。'
        )
        this.sendStyle = 'opacity: 1'
        this.status = '入力'
      } else if (this.status === 'error_self') {
        alert(
          '送信に失敗しました。\nシステムに問題が発生している恐れがあります。\nお手数ですが、お電話でお問い合わせ下さい。'
        )
        this.sendStyle = 'opacity: 1'
        this.status = '入力'
      } else if (this.status === 'error_request') {
        alert(
          '送信に失敗しました。\n・端末がインターネットに接続されているか\nを確認して再度お試しください。'
        )
        this.sendStyle = 'opacity: 1'
        this.status = '入力'
      }
    }
  }
})
